import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Conversation } from 'src/app/interfaces/conversation.interface';
import { ChatService } from 'src/app/services/chat.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.css'],
})
export class ConversationComponent implements OnInit, AfterViewChecked {

  @ViewChild('scrolledDiv') private myScrollContainer!: ElementRef;

  public selectedConversation!: Conversation | undefined;
  public currentConversation!: Conversation | undefined;

  public throttle: number = 300;
  public scrollDistance: number = 1;
  public scrollUpDistance: number = 2;
  private toEnd: boolean = false;
  public isAssigned: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private helperService: HelperService, 
    private chatService: ChatService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.helperService.isClean.subscribe((event) => {
      if(event) {
        this.selectedConversation = undefined;
      } else {
        this.selectedConversation = this.currentConversation;
      }
    }));

    this.subscriptions.push(this.helperService.seeConversation.subscribe((conversation) => {
      let isSame = false
      if(this.currentConversation != undefined && this.selectedConversation != undefined){
        isSame = this.currentConversation?.from == this.selectedConversation?.from
      }
      if(isSame && this.selectedConversation != undefined){
        this.selectedConversation.active = conversation.active
      }
      if(conversation.isSelected){
        this.selectedConversation = conversation;
        this.currentConversation = conversation;
        
        if(this.selectedConversation.hasOwnProperty('phoneNumberId') !== undefined){
          this.chatService.getEntryMessageByNumber(this.selectedConversation.phoneNumberId!, this.selectedConversation.from!);
          }

        if(this.selectedConversation.from){
          this.toEnd = true;
        }
      }
    }));

    this.subscriptions.push(this.helperService.newMessageAdded.subscribe((added) => {
      if (added) {
        this.toEnd = true;
        this.helperService.newMessageAdded.next(false);
      }
    }));
  }

  ngAfterViewChecked() {
    if(this.toEnd && this.selectedConversation){
      this.scrollToBottom();
      this.toEnd = false;
    }
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop =
        this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  cleanStoredConversation(event: boolean): void {
    if(event){
      this.selectedConversation = undefined;
      this.currentConversation = undefined;
    }
  }

  isAssignedConversation(event: boolean): void {
    this.isAssigned = event
  }

  toEndConversation(event: boolean): void {
    if(event){
      this.toEnd = event;
    }
  }

}
