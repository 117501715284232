import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  Agent,
  Conversation,
  ConversationView,
  InternalMessage,
} from '../interfaces/conversation.interface';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private notificationService = inject(NotificationsService);
  private newConversations: ConversationView[] = [];

  private conversation$ = new BehaviorSubject<Conversation>({});
  public seeConversation = this.conversation$.asObservable();

  private messages$ = new BehaviorSubject<any>([]);
  public messages = this.messages$.asObservable();

  private conversationView$ = new BehaviorSubject<ConversationView[]>([]);
  public conversationView = this.conversationView$.asObservable();

  private newConversation$ = new BehaviorSubject<Conversation>({});
  public newConversation = this.newConversation$.asObservable();

  private stopper$ = new BehaviorSubject<boolean>(false);
  public stopper = this.stopper$.asObservable();

  private isClean$ = new BehaviorSubject<boolean>(false);
  public isClean = this.isClean$.asObservable();

  public newMessageAdded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private agentInfo$ = new BehaviorSubject<Agent>({
    id: '',
    name: '',
    phoneNumberId: '',
    expiresConversations: '',
  });
  public agentInfo = this.agentInfo$.asObservable();

  public setConversationList(conversations: any, isFirstTime: boolean): void {
    if (isFirstTime) {
      conversations.forEach((conversation: ConversationView) => {
        conversation.isNotes = false;
      });
      this.newConversations = conversations;
    } else {
      if (Array.isArray(conversations)) {
        conversations.forEach((newConversation: ConversationView) => {
          const index = this.newConversations.findIndex(c => c.phoneNumber === newConversation.phoneNumber);
          if (index > -1) {
            this.newConversations[index] = {...this.newConversations[index], ...newConversation};
          } else {
            newConversation.isNotes = false;
            this.newConversations.push(newConversation);
          }
        });
      } else if (typeof conversations === 'object') {
        const index = this.newConversations.findIndex(c => c.phoneNumber === conversations.phoneNumber);
        if (index > -1) {
          this.newConversations[index] = {...this.newConversations[index], ...conversations};
        }
      } else {
        this.newConversations = conversations
      }
    }

    if (Array.isArray(conversations)){
      const diff = this.newConversations.filter(conversation => !conversations.some((oldConversation : any) => conversation.id === oldConversation.id));
      diff.forEach((conversation) => {
        conversation.active = false
      });
    }
    
    this.conversationView$.next([...this.newConversations]);
  }

  public seeCompleteConversation(
    message: any,
    isRefresh: boolean,
    profileName: string = '',
    isNotes: boolean = false,
    isSelected: boolean = false,
    conversation: ConversationView,
    active: boolean = true,
  ) {
    const { from, phoneNumberId, agentsSuscribed } = message[0];
    const { messageBoddy } = message[message.length - 1];
    const currentFrom: any = this.conversation$.getValue();

    if (currentFrom.from === from && isRefresh === false) return;

    let messages: InternalMessage[] = [];

    message.forEach((element: any) => {
      if (element.messageBody != '' || element.mediaId != '') {
        messages.push({
          from: element.from,
          messageBody: element.messageBoddy
            ? element.messageBoddy
            : element.messageBody,
          type: element.type,
          timestamp: element.timestamp,
          mediaId: element.mediaId,
          mediaType: element.mediaType || element.messageType,
          agentName: element.agentName ? element.agentName : '',
          fileName: element.fileName ? element.fileName : 'Archivo adjunto',
        });
      }
    });

    const newFrom = from || currentFrom.from;
    const messagesList = {
      profileName,
      from:newFrom,
      messages,
      phoneNumberId,
      lastMessage: messageBoddy,
      isNotes,
      isSelected,
      agentsSuscribed,
      active: conversation.active,
    };

    this.conversation$.next(messagesList);
  }

  public setEntryMessage(messages: any): void {
    let hash: any = {};
    messages = messages.filter((current: any) => {
      let go =
        current.timestamp !== undefined
          ? String(current.messageBoddy) + String(current.timestamp)
          : String(current.messageBoddy);

      let exists = !hash[go] || false;

      hash[go] = true;

      return exists;
    });

    this.messages$.next(messages);
  }

  public setAgentInfo(agent: Agent): void {
    this.agentInfo$.next(agent);
  }

  public setStopper(stop: boolean): void {
    this.stopper$.next(stop);
  }

  public cleanCurrentConversation(clean: boolean): void {
    this.isClean$.next(clean);
  }

  public setConversationMode(id: string, isNotes: boolean): void {
    this.newConversations.forEach((element: ConversationView) => {
      if (id === element.id) {
        element.isNotes = isNotes;
      }
    });

    this.conversationView$.next(this.newConversations);
  }

  ngOnDestroy() {
    this.conversationView$.next([]);
    this.conversationView$.unsubscribe();

    this.newConversation$.next({});
    this.newConversation$.unsubscribe();

    this.messages$.next({});
    this.messages$.unsubscribe();

    this.agentInfo$.next({
      id: '',
      name: '',
      phoneNumberId: '',
      expiresConversations: '',
    });
    this.agentInfo$.unsubscribe();

    this.conversation$.next({});
    this.conversation$.unsubscribe();

    this.stopper$.next(false);
    this.stopper$.unsubscribe();

    this.isClean$.next(false);
    this.isClean$.unsubscribe();
  }
}
