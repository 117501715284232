<app-conversation-header
  [username]="
    selectedConversation !== undefined ? selectedConversation.profileName : ''
  "
  [state]="selectedConversation !== undefined ? selectedConversation.state : ''"
  [active]="selectedConversation !== undefined ? selectedConversation.active : false"
  [phoneNumber]="
    selectedConversation !== undefined ? selectedConversation.from : ''
  "
  [conversationInfo]="[
    selectedConversation !== undefined
      ? selectedConversation.phoneNumberId
      : '',
    selectedConversation !== undefined ? selectedConversation.from : ''
  ]"
  (cleanStoredConversation)="cleanStoredConversation($event)"
  [isAssigned]="selectedConversation !== undefined ? selectedConversation.isAssigned : false"
></app-conversation-header>

<div class="infoText" *ngIf="!selectedConversation">
  <mat-card>
    <mat-card-content
      >Por favor, selecciona una conversación para
      visualizarla</mat-card-content
    >
  </mat-card>
</div>

<div
  [ngClass]="selectedConversation.isAssigned ? 'chatContainer' : 'chatContainerUnassigned'"
  *ngIf="selectedConversation"
  infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollUpDistance]="scrollUpDistance"
  [infiniteScrollThrottle]="300"
  #scrolledDiv
>
  <app-message (toEnd)="toEndConversation($event)" [selectedConversation]="selectedConversation"></app-message>
</div>

<app-message-input
  *ngIf="selectedConversation && selectedConversation != undefined"
  [selectedConversation]="selectedConversation"
  (isAssignedConversation)="isAssignedConversation($event)"
></app-message-input>
