export const MESSAGE_STATUS = {
  DELIVERED: 'delivered',
  SEND: 'send',
  READ: 'read',
  FAILED: 'failed',
};

export const MAXIMUM_SIZE = {
  audioLimit: 16000000,
  documentLimit: 50000000,
  imageLimit: 5000000,
  videoLimit: 16000000,
};

export const ALLOWED_TYPES = {
  audioTypes: ['audio/aac', 'audio/mp4', 'audio/mpeg', 'audio/amr', 'audio/ogg', 'audio/opus'],
  documentTypes: [
    'text/plain',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  imageTypes: ['image/jpeg', 'image/png', 'image/jpg'],
  videoTypes: ['video/mp4', 'video/3gpp'],
};

export const QUESTION_TEXT = {
  store: 'Deseas finalizar y archivar la conversación',
  join: 'Deseas unirte a la conversación',
  reactivate: 'Deseas reactivar la conversación'
}

export const CONFIRM_TEXT = {
  store: 'Si archivas la conversación, ya no aparecerá en la pantalla principal.',
  join: 'Al confirmar, recibirás todas las notificaciones relacionadas a esta conversación.',
  reactivate: 'Si habilitas la conversación, aparecerá en la pantalla principal.'
}

export const SUCCESS_TEXT = {
  store: 'Conversación finalizada y archivada correctamente.',
  reactivate: 'Conversación reactivada con éxito.',
}

export const MILLISECONDS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MILLISECONDS_PER_MINUTE = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE; // 60000
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const MILLISECONDS_PER_HOUR = MILLISECONDS_PER_MINUTE * MINUTES_PER_HOUR; // 3600000
export const MILLISECONDS_PER_DAY = MILLISECONDS_PER_HOUR * HOURS_PER_DAY; // 86400000